import * as XLSX from 'xlsx'
import {
  formatarData,
  formatarParaNumber,
  formatarValorReais,
  formatarValorPorcentagem,
  formatarValor,
  PascalCaseComEspaco,
} from '@/libs/utils'

const createSheet = (tableData: any[]) => {
  // Usando os dados e colunas para criar a planilha
  const ws = XLSX.utils.json_to_sheet(tableData)

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Relatório Geral')

  // Salvando o arquivo
  XLSX.writeFile(wb, 'relatorio-de-contratos.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item, formatarData) => {
      return {
        CLIENTE: String(item.instituto.sigla).toUpperCase() ?? '',
        'CRIADO EM': item.createdAt ? formatarData(item.createdAt) : '',
        CONTRATO: item.codigo ?? '',
        'NOME DO TOMADOR': PascalCaseComEspaco(item.proposta.segurado.tomador.nomeCompleto) ?? '',
        MATRIULA: item.proposta.segurado.matricula ?? '',
        'VALOR DO CONTRATO': formatarValor(item.proposta.valorComIOF) ?? '',
        TAXAS: formatarValorPorcentagem(item.taxas) ?? '',
        'VALOR DA PARCELA': formatarValor(item.valorParcela) ?? '',
        'Nº DE PARCELAS': item.numeroParcelas ?? '',
        'DATA DE CONSOLIDAÇÃO': item.dtInicio ? formatarData(item.dtInicio) : '',
        STATUS: item.statusFinanceiro,
        CATEGORIA: item.proposta.segurado.situacaoPrevidenciaria,
        'VENDEDOR(A)': item.usuario?.name ? PascalCaseComEspaco(item.usuario?.name) : '',
        'SALDO DEVEDOR': formatarValor(item.saldoDevedor) ?? '',
      }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item, formatarData))
      createSheet(situationItems)
    },
  }
}
